



















































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Provide, Vue } from 'vue-property-decorator'
import { SummaryViewModel } from '../viewmodel/summary-viewmodel'

@Observer
@Component({
  components: {
    StakingButton: () => import('./common/staking-button.vue'),
    StakingInformation: () => import('./common/staking-infomation.vue'),
  },
})
export default class BannerProjectTable extends Vue {
  @Inject() vm!: SummaryViewModel
  @Provide() walletStore = walletStore

  gradientBanner = ['text--base', 'text--base', 'text--lighten-1', 'text--lighten-2', 'text--lighten-3']
}
